import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/MinervaTools.net/MinervaTools.net/src/layout/simpleMdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Closed Beta`}</h1>
    <Seo title={"Beta"} seoTitle="MinervaTools Closed Beta" description="MinervaTools is currently in closed beta with a few select customers to allow us to tweak everything, making it just right." mdxType="Seo" />
    <p>{`MinervaTools is currently in closed beta with a few select customers. This allows us to focus on tweaking, adding features and eliminating bugs in
real conditions. Our beta testers range from small builder teams to one of the top 5 Minecraft Marketplace partners allowing for testing on all
sizes of servers.`}</p>
    <h2>{`Beta Pricing`}</h2>
    <p>{`Our current prices for beta access are 150€ for a license and an aditional charge of 50€ for white labeling.
We're planning to switch to a subscription based model when we go public. All beta users will be transfered to the
subscription based model with 3 free months of service as soon as it is ready.`}</p>
    <h2>{`Beta Applications`}</h2>
    <p>{`If you think you might be a good fit as a beta tester, please send a mail to `}<a parentName="p" {...{
        "href": "mailto:kevin.k@minervatools.net"
      }}>{`kevin.k@minervatools.net`}</a>{`. We'd love to hear from
you and talk to you to find out if the beta program is right for you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      